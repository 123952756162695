import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "filibuster",
  gameID: RegisteredGames.Filibuster,
  isFeatured: true,
  gameName: "Filibuster",
  numPlayers: "2-10",
  category: "Word",
  description:
    "Compete with your friends to form the longest words from the same letters.",
  howToPlay: `
## Gameplay
In Filibuster, your goal is to form the longest words you can from the same letters that your fellow
Congressional colleagues can select.

You can form multiple words, though the longer they are, the better. Each word must have at least
three letters.

The game consists of three rounds.

There are four categories of letter tiles:

* Vowels: A, E, I, O, U (two each)
* Common Consonants: H, N, R, S, T (two each)
* Uncommon Consonants: C, D, F, G, L, M, P (two each)
* Rare Consonants: B, J, K, Q, V, W, X, Y, Z

### During a Round

#### Letter Selection Motions

Within a round, there are 5 "motions", or letter selection phases. You must select at least one
letter in each motion. Each motion is 1 minute long.

* Motion 1: 3 vowels, 3 common consonants, 3 uncommon consonants
* Motion 2: 2 vowels, 1 common consonant, 1 uncommon consonant
* Motion 3: 2 vowels, 1 common consonant, 1 uncommon consonant

*No more vowels after Motion 3*

* Motion 4: 2 rare consonants, 1 common consonant, 1 uncommon consonant
* Motion 5: 2 rare consonants, 1 common consonant, 1 uncommon consonant

Starting from Motion 3, at the end of each motion, you may choose to "yield the floor". That
means that you sit out the remaining motions and no longer qualify for the "Filibusted" bonus.
In return, you will get no penalty for unused letters.

#### Word Formation

After all motions are complete, you have 2 additional minutes to form and submit your final words.

### Scoring

Each unused letter is -3 points, unless you have "yielded the floor".

All words must be at least 3 letters long. Then, each rare consonant in your word increases your
word's length by one. Word lengths convert to points as follows:

Word Length | Points
------------|--------
3 - 5       | 3 - 5
6           | 7
7           | 9
8           | 12
9+          | 15 + 3 per length after 9

For example:

* 'for' (3 letters, 0 rare → 3 length) scores 3 points, while 'fox' (3 letters, 1 rare → 4 length) scores 4 points.
* 'animals' (7 letters, 0 rare → 7 length) scores 9 points, while 'assembly' (8 letters, 2 rares → 10 length) scores 15+3=18 points.

**PRO-TIP**: The scoring system values longer words over more words.

There are two additional bonuses awarded at the end of the round:

* 3 points for player(s) who have **filibusted**: stay past Motion 5, use all their letters, and
  use the most letters in the round.
* 3 points for player(s) who are **sesquipedalians**: form the longest word of the round, taking into account
  rare consonants.

### End of Game

Count up all of your points across rounds. The person with the most points wins! If there are any
ties, the tie is broken by the largest amount of points scored in the last round. Any further ties
are broken by points scored in round 2, then by points scored in round 1.

### UI Tips

* You can press enter to update a word.
* You can create words even if you don't have the letter tiles for them. The word formation area
  will highlight missing letters in red.
* Make sure to confirm your letter selections. Just clicking the letters is not enough! 
* Make sure to confirm your final words. All words should have a check mark to indicate successful
  submission. If the final words list is empty, click the "Update Final Words" button.
* When you submit your final words, the server will run them through a Scrabble dictionary. You can
  submit as many times until the end of the Word Formation phase.

`,
  links: [
    {
      displayName: "Feedback Form",
      link:
        "https://docs.google.com/forms/d/e/1FAIpQLSdduXcQHg9NTYvcC1I18Htob4c1vpYsSbC25rytaH-Jw5F8EA/viewform?usp=sf_link",
    },
  ],
};

export default config;
