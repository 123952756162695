import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "decrypto",
  gameID: RegisteredGames.Decrypto,
  isFeatured: false,
  gameName: "Decrypto",
  numPlayers: "4+",
  category: "Word",
  description:
    "Give clues about your words without reviewing them to your opponents.",
  howToPlay: `
Split into two teams. Each team will have a set of 4 secret words.

## Gameplay
In each round, each team will have a turn.

During a team's turn, one person from the team is the clue-giver. They will receive a
clue card with three indices (e.g. 4,2,3). They will come up with three single-word
clues for their team to try to guess the indices from their secord words.

Their opponents will also have an opportunity to guess the indices.

If the team fails to guess the correct indices, they get a LOSS.
If the opponent guesses the correct indices, they get a WIN.

The given clues and guesses accumulate over time.

## Scoring
At the end of a round:

* If a team has 2 WINs, they win the game.
* If a team has 2 LOSSes, they lose the game.
* Tie situations:
  - If a team has both 2 WINs and 2 LOSSes.
  - Both teams have 2 WINs, or both have 2 LOSSes.
  - The 8th round completed.
* Tiebreaking:
  - Add up the WINs and substract the LOSSes.
  - If there is still a tie, there is an additional tiebreaker round where both teams
    guess their opponent's secret words. The most successfull guesses wins.
`,
  links: [
    {
      displayName: "Rules",
      link: "http://fgbradleys.com/rules/rules6/Decrypto%20-%20rules.pdf",
    },
  ],
};

export default config;
