import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "donuts",
  gameID: RegisteredGames.Donuts,
  isFeatured: false,
  gameName: "Donuts",
  numPlayers: "2-6",
  category: "Drafting",
  description:
    "Choose your favorite donuts. But if you choose the same donut as someone else, both of you are out of luck.",
  howToPlay: "",
  links: [
    {
      displayName: "Rules",
      link: "https://gamewright.com/pdfs/Rules/GNFD_rulebook.pdf",
    },
  ],
};

export default config;
