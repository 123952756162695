import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "fox",
  gameID: RegisteredGames.Fox,
  isFeatured: true,
  gameName: "Fox in the Forest",
  numPlayers: "2",
  category: "Trick-taking",
  description: "Two-player trick-taking game.",
  howToPlay: `
## Gameplay
Each round has 13 tricks.

When a player reaches goal number of points, then the winner is the player with the most points.

Tiebreaker: number of points scored in the last round.

The trump suit is determined by the face-up card.

* 1: If you play this and lose the trick, you lead the next trick.
* 3: When you play this, you may exchange the decree card with a card from your hand.
* 5: When you play this, draw 1 card. Then, discard any 1 card to the bottom of the deck.
* 7: The winner of the trick receives 1 point for each 7 in the trick.
* 9: When determining the winner of a trick with only one 9, treat the 9 as if it were the trump suit.
* 11: When you lead with this, if your opponent has a card of that suit, they must play either the 1 or their highest card of that suit.

Tricks Won | Points
-----------|--------
0-3        |  6
4          |  1
5          |  2
6          |  3
7-9        |  6
10-13      |  0
`,
  links: [],
};

export default config;
