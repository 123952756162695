import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "cabo",
  gameID: RegisteredGames.Cabo,
  isFeatured: true,
  gameName: "Cabo",
  numPlayers: "2-4",
  category: "Card, Memory",
  description: "Test your memory. Swap your cards for smaller cards.",
  howToPlay: `
The goal of every round is to work towards having the smallest sum in your hand by the end of the round.

## Gameplay

At the start of each round, you get to look at two of your four cards.
During your turn in a round, you can either 1) draw from the deck or 2) draw from the discard pile.

### Draw from the Deck

After drowing from the deck, you can either:

* Discard the card. You may active a special ability.

7/8 = PEEK. Look at one of your cards.
9/10 = SPY. Look at one of your opponent's cards.
11/12 = SWAP. Swap one of your cards with one of your opponent's cards.

* Exchange. See below.

### Draw from the Discard Pile
* Exchange. See below.

### Call Cabo:
* Every other player gets one more turn.

## Exchanging
You can exchange one *or more* of your cards of the **same** value (e.g. all 6s).
If the cards you chose to exchange do not match, you keep all of those cards, plus
the new card you receive. If 3+ cards do not match, you draw an additional penalty card.

## Scoring
If you call Cabo and have the lowest sum (or tie), you score 0 points, else 10 plus your sum.
Otherwise, you score the sum of your cards.
The game ends when one player has more than N points. The player with the lowest total score
wins. Break ties by the lowest score in the final round.

### Shoot the Moon
If a player ends a round with exactly two 12s and two 13s, they score 0 points
and everyone else scores 50 points.

### Exact Match Reset
Once per game, if a player has exactly N points, then they get their score reset to N/2.
`,
  links: [
    {
      displayName: "Rules",
      link:
        "https://cdn.shopify.com/s/files/1/0740/4855/files/Cabo-online-rules.jpg?330",
    },
  ],
};

export default config;
