import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "justone",
  gameID: RegisteredGames.JustOne,
  isFeatured: true,
  gameName: "Just One",
  numPlayers: "4+",
  category: "Word, Cooperative",
  description:
    "Provide one word clues to guess a word. The same clues will be erased.",
  howToPlay: `
The goal of the game is to get as many words correct as one team.

## Gameplay
On each turn, one person will be the guesser. All others will be cluegivers.

1. The guesser chooses a number from 1-5, which yields a random word.
2. The cluegivers see the chosen word and give a *one-word* clue.
3. A designated cluegiver will remove duplicates or invalid clues.
4. The guesser will guess the word.

## Scoring

If the guesser guesses **wrong**, the next round will be skipped as a penalty.
Guessers have the option to *skip* if they do not have a guess.
  
## Invalid Clues

* Clues that are more than one word.
* Clues that are duplicated.
* Clues that contain the same word family as the word.
`,
  links: [
    {
      displayName: "Rules",
      link: "http://justone-the-game.com/files/rules/JustOne-Rules-EN.pdf",
    },
  ],
};

export default config;
