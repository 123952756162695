import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "deepsea",
  gameID: RegisteredGames.DeepSea,
  isFeatured: true,
  gameName: "Deep Sea",
  numPlayers: "1-6",
  category: "Push Your Luck",
  description:
    "Keep exploring for more valuable treasure, or head back. Just make sure to head back in time!",
  howToPlay: `
## Gameplay
There are 3 rounds in the game. In each round, each player will take turns in order. On a single turn:

1. Breathe. Reduce the oxygen count by the number of chips you are carrying.
1. Declare whether you will turn back. If you declare to turn back, that decision is final for the round.
1. Roll the dice. Move based on dice minus the number of chips you are carrying. Jump over players without counting their spot.
1. Pick up treasure (if spot has a chip), drop treasure (if spot is empty), or do nothing.

The round ends when the oxygen level hits 0. That last player shall complete their turn. 

If you do not make it back, you lose the chips you are carrying. They get placed at the end in
stacks of 3. That stack is considered as one chip in later rounds.

The deepest player goes first in the next round. Or, if everyone has returned, the
last player to return goes first in the next round.

## Scoring

* Level 1: 0-3
* Level 2: 4-7
* Level 3: 8-11
* Level 4: 12-15

The highest sum wins. Break ties by summing the levels of your chips.

## Rule changes

1. Players will not freely choose the order of the abandoned chips.
2. Break ties by summing levels, not by seeing who has the most chips of the highest level.
`,
  links: [
    {
      displayName: "Rules",
      link: "https://www.ultraboardgames.com/deep-sea-adventure/game-rules.php",
    },
  ],
};

export default config;
