import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "werewords",
  gameID: RegisteredGames.Werewords,
  isFeatured: true,
  gameName: "Werewords",
  numPlayers: "4+",
  category: "Word, Social Deception",
  description:
    "Ask questions to guess a word. But watch out! There is a werewolf hidden among you.",
  howToPlay: `
Modification from the main game:
The **mayor** is not on either team. They are just a facilitator of the game.

## Instructions for the Mayor
Tailor the difficulty of the word to your audience. You may want to start off by
choosing an easier word.
Answer all questions truthfully.
  `,
  links: [
    {
      displayName: "Rules",
      link: "https://boardgamegeek.com/filepage/151319/werewords-rules",
    },
  ],
};

export default config;
