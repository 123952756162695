import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "wordguess",
  gameID: RegisteredGames.WordGuess,
  isFeatured: true,
  gameName: "WordGuess",
  numPlayers: "2-10",
  category: "Word",
  description:
    "Word guessing games like Hangman and Toss Up from Wheel of Fortune.",
  howToPlay: `
## Gameplay

### Hangman Mode
Each turn, one person can choose to guess a letter or the word.
There is a limited number of guesses across all guessers.

### Toss Up Mode
This mode is like Wheel of Fortune's Toss Up.

The host will choose a phrase, and the game reveals one letter per second.

Buzz in to guess the phrase and score a point. However, if you guess wrong,
you are out for the rest of the round.
`,
  links: [],
};

export default config;
