import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "devilsplan",
  gameID: RegisteredGames.DevilsPlan,
  isFeatured: false,
  gameName: "Devil's Plan",
  numPlayers: "2-10",
  category: "Various",
  description:
    "Various games as seen on The Devil's Plan show. Current games include Hexagon.",
  howToPlay: `
## Gameplay

### Hexagon Mode
You will be presented with a hexagon of numbers to memorize. After the memorization period, the
numbers will become hidden under corresponding letters. Then, you will be given a target number.
Correct answers for this hexagon are straight path of 3 cells that add up to the target number.

You can buzz in to supply the correct answer as three letters in any order, as long as the 3
letters form a straight line path in the hexagon.

* If you are right, you gain a point.
* If you are wrong, you lose a point. You can end up with a negative score.

You can buzz multiple times during a round, even if you provide incorrect answers.

`,
  links: [],
};

export default config;
