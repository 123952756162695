import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "ra",
  gameID: RegisteredGames.Ra,
  isFeatured: false,
  gameName: "Ra",
  numPlayers: "2-5",
  category: "VP,auction",
  description: "Bid for tiles and accumulate wealth in your kingdom.",
  howToPlay: `
## Gameplay
There are three rounds in Ra. In each round, players will take one of three actions:

1. Draw a tile.
2. Invoke Ra.
3. Spend God tile(s) on tile(s) on the auction row.

### Auction
An auction starts when either a Ra tile is drawn or a player invokes Ra. Starting from
the next player, players will bid for the tiles on the auction row. The winner will
exchange their winning bid with the center disk, then place the center disk face down.

If invoked, the invoking player must bid if everyone else passes.

### End of Round
The round ends when either all players' disks are face down or the Nth ra tile has been drawn.

## Tiles and Scoring
Scoring is done each round and at the end of the game.

There are 180 tiles and they do not refresh.
- 5 of each culture, 4 culture breakers
- 5 of each monument, 2 monument breakers
- 25 Nile, 12 flood, 2 water breakers
- 5 gold
- 25 Pharaoh, 2 pharaoh breakers
- 8 God tiles
- 30 Ra tiles

### Round Scoring

* Gold: 3 points each
* Unspent God: 2 points each
* Culture:
  - 0 types of culture: -5 points
  - 3 types of culture: 5 points
  - 4 types of culture: 10 points
  - 5 types of culture: 15 points
* Pharaoh:
  - Player(s) with most: 5 points
  - Player(s) with least: -2 points
  - All players equal: 0 points
* Water:
  - You must have a flood tile to score.
  - Each water tile = 1 point.

### End of Game Scoring

* Monuments:
  - Each group of 3 identical monuments: 5 points
  - 4 identical: 10 points
  - 5 identical: 15 points
  - 6 or fewer types of monuments: N points
  - 7 types: 10 points
  - 8 types: 15 points

`,
  links: [
    {
      displayName: "Rules",
      link:
        "https://images.zmangames.com/filer_public/1c/f1/1cf1f19e-70bb-413f-8756-13e1e5239b8a/kn27_ra_rulebook_eng.pdf",
    },
  ],
};

export default config;
