import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "jeopardy",
  gameID: RegisteredGames.Jeopardy,
  isFeatured: true,
  gameName: "Jeopardy",
  numPlayers: "2+",
  category: "Trivia",
  description:
    "Play a Jeopardy game from J-Archive or a custom game. This web app will help you keep track of your scores and who rings in first.",
  howToPlay: `
## Loading Games
* To load a game from J-Archive, use the number in the URL, *not* the number of the show.
* To load a custom game from Dickson's [Custom Jeopardy Github Repo](https://github.com/dicksontsai/custom-jeopardy), use the JSON file name (without ".json").

## Host Instructions - Reading Clues mode
After you finish reading the clue, unlock the clue for players to buzz in.
You will also need to unlock Daily Doubles and Final Jeopardy.
`,
  links: [
    {
      displayName: "J-Archive",
      link: "http://j-archive.com",
    },
  ],
};

export default config;
