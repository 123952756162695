import { RegisteredGames } from "../../serverTypes/src/shared/enums/platform/game";
import { GameConfig } from "../../platform/GameSite";

const config: GameConfig = {
  directory: "mind",
  gameID: RegisteredGames.Mind,
  isFeatured: true,
  gameName: "The Mind",
  numPlayers: "2-4",
  category: "Cooperative",
  description: "Try to play numbers in ascending order. Concentration and teamwork are important!",
  howToPlay: `
## Gameplay
Each player gets a hand with N cards from 1-100, where N is the current level.
All players must play their cards in ascending order without communicating anything.
If a player plays a card larger than other cards that exist, those other cards get discarded and a life is lost.

### Stars
You can propose to use a star. Once everyone agrees, everyone will discard their lowest card. This card becomes public information.
`,
  links: [{
    displayName: "Rules",
    link: "https://boardgamegeek.com/filepage/164796/english-rules",
  }],
};

export default config;
